@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* #region  /**=========== Primary Color =========== */
  --tw-color-primary-50: 250 250 249;
  --tw-color-primary-100: 245 245 244;
  --tw-color-primary-200: 231 229 228;
  --tw-color-primary-300: 214 211 209;
  --tw-color-primary-400: 168 162 158;
  --tw-color-primary-500: 120 113 108;
  --tw-color-primary-600: 87 83 78;
  --tw-color-primary-700: 68 64 60;
  --tw-color-primary-800: 41 37 36;
  --tw-color-primary-900: 28 25 23;
  --color-primary-50: rgb(var(--tw-color-primary-50)); /* #fafaf9 */
  --color-primary-100: rgb(var(--tw-color-primary-100)); /* #f5f5f4 */
  --color-primary-200: rgb(var(--tw-color-primary-200)); /* #e7e5e4 */
  --color-primary-300: rgb(var(--tw-color-primary-300)); /* #d6d3d1 */
  --color-primary-400: rgb(var(--tw-color-primary-400)); /* #a8a29e */
  --color-primary-500: rgb(var(--tw-color-primary-500)); /* #78716c */
  --color-primary-600: rgb(var(--tw-color-primary-600)); /* #57534e */
  --color-primary-700: rgb(var(--tw-color-primary-700)); /* #44403c */
  --color-primary-800: rgb(var(--tw-color-primary-800)); /* #292524 */
  --color-primary-900: rgb(var(--tw-color-primary-900)); /* #1c1917 */
  /* #endregion  /**======== Primary Color =========== */
}

@layer base {
  /* inter var - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100 900;
    font-display: optional;
    src: url('/fonts/inter-var-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
      U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  .cursor-newtab {
    cursor: url('/images/new-tab.png') 10 10, pointer;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-syncopate text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-syncopate text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply font-syncopate text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply font-syncopate text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply font-syncopate text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply font-primary text-sm md:text-base;
  }
  /* #endregion  /**======== Typography =========== */

  .layout {
    /* 1100px */
    max-width: 68.75rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(to right, var(--color-primary-400), var(--color-primary-500));
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color, border-color;
    }
  }
  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }

  /* Hide input[number] arrows */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

html {
  /* Eventually: https://caniuse.com/mdn-css_properties_scrollbar-gutter */
  overflow-y: overlay;
}

#gallery :nth-child(7n - 6) figcaption {
  background-color: #6fc3dfe0;
}
#gallery :nth-child(7n - 5) figcaption {
  background-color: #8d82c4e0;
}
#gallery :nth-child(7n - 4) figcaption {
  background-color: #4db6ace0;
}
#gallery :nth-child(7n - 3) figcaption {
  background-color: #ec8d81e0;
}
#gallery :nth-child(7n - 2) figcaption {
  background-color: #e7b788e0;
}
#gallery :nth-child(7n - 1) figcaption {
  background-color: #8ea9e8e0;
}
#gallery :nth-child(7n - 0) figcaption {
  background-color: #87c5a4e0;
}
